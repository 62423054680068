import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Hem: "/",
  "Analytics Consulting": "/se/analysrådgivning",
  "Tagghantering": "/se/tagghantering"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/tag-management"
);

// Schema
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Vad är ett tagghanteringssystem?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ett tagghanteringssystem är ett JavaScript-bibliotek för att implementera och konfigurera analys- och marknadsföringstaggar. HTML-taggar, JavaScript-kodsnuttar eller spårningspixlar lägger till funktioner på din webbplats eller app och kan installeras med bara några klick. Efter att en tagg har publicerats i containern, genereras koden och läggs till på webbplatsens sida (DOM). Ingen ny källkod implementeras på servern."
        }
      },
      {
      "@type": "Question",
      "name": "Vilket problem löser ett TMS?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Med tiden har fler sociala medieplattformar kommit med sina egna taggar (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, etc.) och webbplatser har blivit överbelastade med deras kodsnuttar. Som ett resultat har företagen fått ökade webb-utvecklingskostnader. Tagghanterare löser dessa problem eftersom implementeringar kan skalas upp och därmed spara tid, vilket innebär att företag kan spara pengar."
      }
    }
  ]
}
`;

const TagManagement = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Tagghantering förklarat (med ${currentYear} jämförelse)`}
        description="Lär dig vad tagghanteringssystem gör, deras fördelar, och varför de blev så populära (inklusive en jämförelse av de nuvarande TMS-leverantörerna)."
        lang="se"
        canonical="/se/tagghantering"
        image="tag-management-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="tag-management/tag-management-hero.png"
          alt="logotyper av TMS-plattformsleverantörer bredvid varandra på mörk bakgrund"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Tagghantering</H>
        <p className="baseline">
          Tagghantering njuter av ökande popularitet och en växande community. De flesta moderna webbplatser kan inte längre klara sig utan ett tagghanteringssystem, så att nu använder ungefär <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">30% av alla webbplatser en tagghanterare</a>.
        </p>
        <p>Men detta är en ganska ny trend inom marknadsföringsteknologi.</p>
        <p>
          Under de senaste åren har en omvandlande ny sektor av tagghanteringslösningar uppstått. Än idag kommer nya lösningar ut på marknaden, på grund av förändrade regler och utvecklande marknadsföringsteknologi.
        </p>
        <p className="baseline">
          En bra illustration av det växande intresset är den globala sökningen efter "Google Tag Manager" (GTM) - det mest populära tagghanteringssystemet (TMS) med en <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">marknadsandel på 94% användningsfördelning på hela internet</a>:
        </p>
        <ImgScreenshot
          src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
          alt="Graf från Google Trends som visar ökande intresse för Google Tag Manager"
          caption="Ökningen i sökningar efter 'Google Tag Manager' sedan 2010 illustrerar det växande intresset för tagghanteringssystem. Ironiskt nog blev den generella termen 'tagghantering' aldrig lika populär som TMS-plattformsleverantörerna."
          className="article-img"
        />
        <p className="baseline">Trots att den extrema tillväxten har pågått ett tag nu, förväntas användningen av tagghanterare att <b>fortsätta växa</b> med en årlig tillväxttakt på <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="_blank">5,7% fram till 2024.</a></p>
        <H as="h2">Vad är ett tagghanteringssystem?</H>
        <p>
          Ett tagghanteringssystem är ett JavaScript-bibliotek för att implementera och konfigurera analys- och marknadsföringstaggar.</p>
        <p>
          HTML-taggar, JavaScript-kodsnuttar eller spårningspixlar lägger till funktioner på din webbplats eller app och kan installeras med bara några klick.
        </p>
        <H as="h3">Exempel på taggar</H>
        <ul>
          <li>Google Analytics-spårning</li>
          <li>Videospårning</li>
          <li>Retargeting-pixlar (Facebook, Google Ads, LinkedIn etc.)</li>
          <li>Registreringsformulär</li>
        </ul>
        <p>
          Taggimplementeringar kan göras genom ett grafiskt användargränssnitt (GUI) för enkel installation.
        </p>
        <p>Efter att en tagg har publicerats i containern, genereras koden och läggs till på webbplatsens sida (DOM). Ingen ny källkod implementeras på servern.</p>
        <p>Det mest populära TMS är <Link to="/se/vad-ar-google-tag-manager">Google Tag Manager</Link>. Det kommer med en mängd olika taggtyper och mallar tillgängliga för tredje parts leverantörer så att du kan implementera taggar med bara några klick.</p>
        <p>Implementeringen baseras på 3 komponenter:</p>
        <ul>
          <li><strong>Taggar</strong> (kod som ska läggas till på sidan)</li>
          <li><strong>Triggers</strong> (regler med villkor som säger när taggen ska avfyras)</li>
          <li><strong>Variabler</strong> (dynamiska data som ska användas i taggar eller triggers)</li>
        </ul>

        <ImgScreenshot
          src="generic/google-tag-manager-overview-tab-en.png"
          alt="Översikt över Google Tag Manager"
          className="article-img"
          caption="Översikt över Google Tag Manager med panelen till vänster som visar flikar för konfiguration av taggar, triggers och variabler."
        />
        <p>Konfigurationen utförs med enkla formulär och inmatningsfält istället för att behöva koda den.</p>
        <p>Detta minskar fel och gör implementeringen av webbplatstaggar <b>tillgänglig för digitala marknadsförare utan programmeringsbakgrund.</b></p>

        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Triggerkonfiguration i GTM"
          className="article-img"
          caption="Triggerkonfiguration i GTM. Den definierar när en tagg ska köras. Denna körs när något HTML-element klickas som matchar CSS-selektorn."
        />

        <H as="h2">Vilket problem löser ett TMS?</H>
        <p>
          Med web 2.0 och förbättrad marknadsföringsteknologi har nya möjligheter för digitala marknadsförare uppstått. De har gjort det möjligt att analysera användarbeteenden för segmentering och använda data för reklamkampanjer.
        </p>
        <p>För att köra sådana riktade kampanjer måste flera <b>taggar eller pixlar</b> först placeras på webbplatsen. Därefter kan spårningspixeln kategorisera besökare och deras beteende för att ommarknadsföra dem på Facebook eller Google Ads.</p>
        <p>Med tiden har fler sociala medieplattformar kommit med sina egna taggar (LinkedIn, Snapchat, Twitter, Pinterest, Instagram, etc.) och webbplatser har blivit överbelastade med deras kodsnuttar.</p>
        <p>Det blev inte ovanligt att se 20 till 40 individuella taggar i <code>{`<head>`}</code> sektionen av en sida.</p>
        <p>Grunden för detta taggningssystem är en välkonfigurerad <Link to="/se/händelsespårning">event tracking setup</Link>. Alla relevanta knappar måste utrustas med JavaScript-funktioner. När ett element klickas, kommer JavaScript att notifiera den relevanta marknadsföringstaggen.</p>
        <p>Taggarna behövde mestadels samma användarmått, men om du ville lägga till en annan dimension eller mått blev det snabbt en tung manuell uppgift. Så hanteringen av dessa taggar blev ett ännu större problem.</p>
        <p>Så utvecklare var tvungna att spendera många timmar med att klistra in JavaScript-kod i HTML-element. Att veta exakt hur koden skulle skrivas eller vilka variabler som kunde skickas med blev en ny organisatorisk uppgift.</p>
        <p>Företag som ville köra kampanjer ofta var tvungna att sätta upp event tracking på alla nya landningssidor för att hålla kampanjspårningen igång. Att hålla huvudtaggar, parametrar och variabler uppdaterade och i linje med uppdateringar blev en ytterligare uppgift.</p>
        <p>Varje experimentell idé om att spåra en ny interaktion blev en ansträngning som var svår att motivera. Kodändringar blev för arbetskrävande.</p>
        <p>Kort sagt, förändringar och experimenterande var svårt och oflexibelt. Men konverteringsspårning behövde upprätthållas för att mäta kampanjens prestation. Att inte mäta det ordentligt skulle annars göra det svårt att förbättra.</p>
        <p>Som ett resultat ökade företagens webb-utvecklingskostnader. Problemet kunde bara lösas med egenutvecklade bibliotek, vilket tyvärr ledde till att utvecklingsteamen löste problemet i silos och uppfann hjulet på nytt.</p>
        <p>Marknaden var mogen för innovation.</p>

        <p>Tagghanterare löser dessa problem eftersom implementeringar kan skalas upp och därmed <b>spara tid</b>, vilket innebär att företag kan <b>spara pengar</b>.</p>
        <p>Ironiskt nog implementeras ett tagghanteringssystem också genom en <Link to="/se/wiki-analytics/behållar-tag">container tag</Link> på alla sidor. Men alla senare taggar kan läggas till via TMS själv.</p>
        <p>Tagghanterarkoden måste läggas till och implementeras endast en gång i början och alla följande taggar implementeras genom tagghanteringsverktygets grafiska användargränssnitt.</p>

        <ImgScreenshot
          src="tag-management/matomo-trigger-types.png"
          alt="Lista över tillgängliga triggetyper i Matomo Tag Manager-verktyget"
          className="article-img"
          caption="Typer av triggers i Matomo. Triggers definierar när en viss kod ska köras och det finns en lång lista att välja från - inga kodningskunskaper krävs."
        />

        <p>Arbetsbelastningen minskas och proceduren <b>förenklas avsevärt</b>.</p>
        <p>Genom den förenklade implementeringen kunde <Link to="/se/google-tag-manager-konsult">tagghanteringskonsulter</Link> konfigurera datainsamlingen av spårningstaggar. Denna nya roll i organisationen fokuserar på JavaScript-programmering enbart för webbanalys och digitala marknadsföringstjänster.</p>
        <p>Varje regel och varje datapunkt kunde nu användas över flera taggar och till och med över flera domäner.</p>
        <p>En tagghanteringsplattform har många fördelar. Så många faktiskt, att du sällan hör några klagomål.</p>

        <H as="h2">Fördelar</H>
        <p>
          En av de stora fördelarna med tagghanteringssystem är att en teknisk marknadsförare kan implementera marknadsföringstaggar utan att vara beroende av en utvecklare.
        </p>

        <p>Det grafiska användargränssnittet gör det enkelt för en digital marknadsförare att utföra grundläggande implementeringar som annars skulle vara för svåra i rå kod.</p>

        <p>Som ett <b>exempel</b> här är listan över marknadsföringsverktyg och analys-taggar du kan installera med <b>Matomo Tag Manager</b>:</p>
        <ImgScreenshot
          src="tag-management/matomo-tag-templates.png"
          alt="Lista över taggtyper i Matomo"
          className="article-img"
          caption="Taggtyp lista från Matomo - Taggar kommer med en fördefinierad mall, så att endast formulärfält måste fyllas i för implementering."
        />
        <p>Nedan ser du en lista över GTM:s tillgängliga <b>taggval</b> (<a href="https://support.google.com/tagmanager/answer/6106924?hl=en" target="_blank" noopener>komplett lista över stödda taggar</a>). Jämförelsevis har GTM de flesta taggmallarna tillgängliga samt <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>anpassade mallar</a>.</p>
        <ImgScreenshot
          src="tag-management/gtm-tag-types.png"
          alt="Lista över taggar från GTM"
          className="article-img"
          caption="Lista över taggar från GTM - Jag var tvungen att förkorta tagglistan för att få plats. Det finns ännu fler taggmallar om du tittar under anpassade mallar."
        />

        <p className="baseline">Eftersom taggdistribution sker på några sekunder och ett marknadsföringsteam inte längre behöver vänta på koddistribution på servern, sparar användningen av ett TMS <b>tid, kostnader och minskar övergripande projektledning</b>.<br />Detta bekräftas i en rapport från Econsultancy från 2015, där <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">73% av marknadsförare sa att användningen av ett TMS hjälper till att minska kostnaderna</a>.</p>
        <p>Med denna flexibilitet är agila arbetsflöden möjliga som tillåter gradvisa kampanjoptimeringar, tester och experiment.</p>
        <p>Digitala marknadsföringskampanjer kan köras i fler varianter medan de riktar sig mot mer specifika grupper, vilket ökar konverteringar och ger en bra grund för att lära sig hur grupper svarar på anpassade meddelanden.</p>
        <p>Företag som redan har data-silos tillgängliga, som databaser, CRM:er och API:er kan använda ett <Link to="/se/data-lager">data lager</Link> för att göra datan tillgänglig för kampanjspårningsregler.</p>
        <p>En annan underskattad fördel är de tillgängliga inställningarna för arbetsytor, miljöer och användarrättigheter som utgör en hanteringslösning för att ge tillgång till byråer eller externa konsulter. Åtkomst kan beviljas på några sekunder för att låta någon arbeta på en enskild tagg eller hela taggningsplanen.</p>

        <p>Sammanfattningsvis är <b>fördelarna med tagghantering</b> följande:</p>
        <ul>
          <li>Kostnadsbesparingar, på grund av lägre implementeringskostnader</li>
          <li>Snabba implementeringar</li>
          <li>Ökad flexibilitet eftersom ändringar och korrigeringar kan implementeras på några sekunder</li>
          <li>Robust och stabil integration av externa datakällor</li>
          <li>Möjlighet att enkelt tilldela olika användarrättigheter till externa partners</li>
        </ul>
        <H as="h3">Nackdelar</H>
        <p>Det finns en fundamentalt motiverad oro att en <b>webbplats laddningshastighet saktar ner</b> när man använder ett TMS.</p>
        <p>Men detta är fallet endast i mycket begränsad utsträckning, på grund av asynkron laddning av taggar.</p>
        <p className="baseline">Webbplatser med ett välkonfigurerat TMS brukar <b>ladda snabbare</b> än om taggar implementerades direkt i källkoden. En rapport från Econsultancy från 2015 uppger att <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">64% av TMS-användare ser snabbare webbplatsprestanda</a>.</p>
        <p>Eftersom optimering av laddningshastighet ofta brutalt försummas och på grund av den minimala förlusten av hastighet orsakad av ett TMS, finns det vanligtvis mycket effektivare metoder för att förbättra laddningstiden än att helt avstå från tagghanteringsverktyg.</p>

        <p>Och nu, vem tror du skulle ha tillräckligt intresse för att underlätta digital reklam och konverteringsspårning för att utveckla en lösning gratis? Det måste vara ett företag som drar nytta av att andra kör digital reklam 😉.</p>

        <H as="h2">Plattformsjämförelse</H>

        <p>Här är en jämförelse av de viktigaste plattformarna på tagghanteringssystemmarknaden:</p>


        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Pris</th>
              <th>Dataägande</th>
              <th>Tillgänglighet<br />av experter</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>GTM (gratis)</b></td>
              <td>gratis</td>
              <td>nej</td>
              <td>hög</td>
            </tr>
            <tr>
              <td><b>GTM 360</b></td>
              <td>hög</td>
              <td>ja</td>
              <td>hög</td>
            </tr>
            <tr>
              <td><b>Adobe Launch</b></td>
              <td>hög</td>
              <td>ja</td>
              <td>medel</td>
            </tr>
            <tr>
              <td><b>Tealium iQ</b></td>
              <td>medel</td>
              <td>ja</td>
              <td>låg</td>
            </tr>
            <tr>
              <td><b>Matomo</b></td>
              <td>låg</td>
              <td>ja</td>
              <td>låg</td>
            </tr>
            <tr>
              <td><b>Piwik</b></td>
              <td>medel</td>
              <td>ja</td>
              <td>låg</td>
            </tr>
          </tbody>
        </table>
        <p>För företag faller valet oftast på premiumplattformar, som <b>Google Tag Manager 360, Adobe Launch</b> (efterträdare till Adobe DTM) eller <b>Tealium iQ</b>, eftersom de uppfyller företagens sekretesskrav för kunddata.</p>
        <p>
          Priset på en premium TMS från Google 360, Adobe eller Tealium kan inte definieras exakt, eftersom de säljs i konfigurerbara företags-paket tillsammans med andra analyslösningar.
        </p>

        <p>
          <u>Priserna är därför endast ungefärliga och bör ses som en grov orientering</u>. Individuella priser är kända för att variera.
        </p>

        <p>Om du vill se hur <b>Tealium Tag Manager</b> ser ut, titta på deras demovideo:</p>
        <YouTube id="HF1ZiPQYP6M" />
        <br />
        <p>Likaså, här är en video där du kan se <b>Adobe Launch</b> gränssnittet och hur en sidladdningsregel ställs in:</p>
        <YouTube id="JR2mRfKU18Y" />
        <br /><br />
        <p>Det mest populära tagghanteringsverktyget är dock <a href="https://tagmanager.google.com/" target="_blank">gratisversionen av Google Tag Manager</a>.</p>
        <H as="h2">Vanliga användningsfall</H>

        <p>Datainsamling för webbanalys, digital marknadsföring eller data science-forskning är de vanligaste användningsområdena för tagghanteringssystem.</p>


        <H as="h3">Webbanalys</H>
        <p>Webbanalys-system som Google Analytics är snabba att ställa in och enklare att skala över flera domäner med hjälp av ett TMS. Eventuella senare tillägg eller ändringar implementeras snabbt och kräver färre resurser, vilket är anledningen till att tagghantering har blivit en standard för digital analys de senaste åren.</p>
        <p><b>Konverteringsoptimering (CRO)</b> är också till stor del baserad på mätvärden från en analys-tagg och är därför starkt kopplad.</p>

        <H as="h3">Digital marknadsföring</H>
        <p>Annonser på plattformar som Facebook, Twitter, Instagram och Google Ads är populära kanaler för trafikanskaffning. Digital annonsering genom retargeting med banners eller Adwords är därför det vanligaste användningsfallet.</p>
        <p>Sådan marknadsföringsteknologi är baserad på taggar som kräver datapunkter från en eller flera digitala egendomar för att skapa användarsegment. Därför är hantering av taggar på ett kontrollerbart, skalbart sätt en förutsättning.</p>
        <p><b>Affiliate-marknadsföring</b> är baserad på att tillskriva konverteringar till trafikreferenser och är också starkt beroende av cookie-logik implementerad genom tagghanterare.</p>

        <H as="h3">Data Science</H>
        <p>Med AI:s framväxt ökade behovet av data eftersom datamodeller måste tränas på enorma mängder data.</p>
        <p>All data som inte kan skrapas från statiska sidor samlas vanligtvis in med en tagghanteringslösning. Därifrån skickas den vidare till ett datalager som är tillgängligt för datamodellering.</p>

        <H as="h2">Tagghanteringskonsulting</H>
        <p>Konsulttjänster för tagghantering dök upp samtidigt med marknadens tillväxt: Ytterligare leverantörer dök upp med nya lösningar och prisnivåer som måste beaktas.</p>
        <p>Även <b>användardataskyddslagar i EU</b> (GDPR) förändrade kraven för konfigurationer och gav integritetsfokuserade leverantörer ett uppsving.</p>
        <p>Konsulttjänster för tagghantering koncentrerar sig på plattformsval, implementering, validering och underhåll av systemet.</p>
        <p>Tagghanteringskonsulter anlitas vanligtvis från byråer som fokuserar på digital annonsering, webbanalys eller så är de <Link to="/se/google-tag-manager-konsult">frilanskonsulter</Link>.</p>

        <H as="h2">Finns det fall där ett TMS inte är rätt lösning?</H>
        <p><b>Ett tagghanteringssystem är rätt val för de flesta webbplatser och appar</b>. Endast webbplatser som verkar inom sektorer med specifika dataskyddskrav bör noggrant titta på hur infrastrukturen är utformad för att följa dataskyddslagstiftningen.</p>
        <p>Detta är dock vanligtvis inte ett fall mot användningen av ett TMS, utan snarare ett fall för att välja rätt.</p>
        <p>En annan övervägning kan vara webbplatsens prestanda.</p>
        <p>Om det inte är korrekt inställt kan ett TMS sakta ner en webbplats. Nischer där annonsering spelar en viktig roll kan ha problem med långsamma laddningstider. Att spara några hundra millisekunder kan tillföra värde, men det är tveksamt om fördelarna med snabbare laddningstid rättfärdigar högre kostnader för att hantera spårningspixlar.</p>
        <p>Beroende på in-house-resursernas kapacitet är det möjligt att driva en webbplats och digital marknadsföring framgångsrikt utan ett TMS, men detta är vanligtvis undantagsfall.</p>
        <p>Alla webbplatser som inte har ett extremt antal sidor eller nischkrav för tredje parts taggar, bör klara sig bra med att kontrollera sina HTML- och JavaScript-taggar med ett TMS.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default TagManagement;
